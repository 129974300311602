import React from "react";

const AboutUs = () => {
  return (
    <>
      <div id="usluge" className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="/img/about-us/about-us.webp" alt="About Us" width="570" height="604" />

                <div className="shape-1">
                  <img src="/img/about-us/shape (1).png" alt="Shape" width="570" height="605" />
                </div>

                <div className="shape-2">
                  <img src="/img/about-us/shape (2).png" alt="Shape" width="289" height="610" />
                </div>

                <div className="shape-3">
                  <img src="/img/about-us/shape (3).png" alt="Shape" width="185" height="185" />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span className="top-title">Usluge</span>
                <h2>Specijalistički lekarski pregledi:</h2>
                <p>
                  Fokusirana na pružanje raznolikih usluga, Hera VaMedic je posvećena 
                  unapređenju kvaliteta života pacijenata kroz širok spektar medicinskih 
                  usluga koje nudi.
                </p>

                <ul>
                  <li>
                    <i className="flaticon-cardiology" />
                    Kardiologa
                  </li>
                  <li>
                    <i className="flaticon-care" />
                    Hematologa
                  </li>
                  <li>
                    <i className="flaticon-liver" />
                    Endokrinologa
                  </li>
                  <li>
                    <i className="flaticon-kidney" />
                    Urologa
                  </li>
                  <li>
                    <i className="flaticon-brain" />
                    Neurologa
                  </li>
                  <li>
                    <i className="flaticon-positive-vote" />
                    Psihijatra
                  </li>
                  <li>
                    <i className="flaticon-walker" />
                    Ortopeda
                  </li>
                  <li>
                    <i className="flaticon-gloves" />
                    Reumatologa
                  </li>
                  <li>
                    <i className="flaticon-heart-1" />
                    Interniste
                  </li>
                  <li>
                    <i className="flaticon-man" />
                    Specijaliste opšte medicine
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
