import React from "react";

const TopHeader = () => {
  return (
    <>
      <div className="top-header-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-6">
              <ul className="header-content-left">
                <li>
                  <i className="bx bx-time"></i>
                  Pon-Pet: 10h-20h, Sub: 08h-13h
                </li>
                <li>
                  <a href="tel:+381668404040" >
                    <i className="bx bx-phone"></i>
                    066/ 8-40-40-40
                  </a>
                </li>
                <li>
                  <a href="tel:+381143106304" >
                  <i className="bx bx-phone"></i>
                    014/ 310-63-04
                  </a>
                </li>
                <li>
                  <a href="mailto:office@heravamedic.rs">
                    <i className="bx bxs-paper-plane"></i>
                    office@heravamedic.rs
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
