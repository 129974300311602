import React from "react";

const Services = () => {
  return (
    <>
      <div className="services-area bg pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-auto"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <div className="single-services">
                <h3>
                  Ultrazvučna dijagnostika:
                </h3>
                <p>
                  - Pregled dojki
                </p>
                <p>
                  - Pregled stomaka
                </p>
                <p>
                  - Pregled štitaste žlezde i mekih tkiva vrata
                </p>
                <p>
                  - Urološki ultrazvučni pregled
                </p>
                <p>
                  - Dopler krvnih sudova vrata
                </p>
                <p>
                  - Dopler krvnih sudova ruku i nogu
                </p>
                <div className="services-shape">
                  <img src="/img/shape/shape (4).webp" alt="Shape" width="252" height="223" />
                </div>
              </div>
            </div>

            <div 
              className="col-lg-auto"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <div className="single-services">
                <h3>
                  Terapije:
                </h3>
                <p>
                  - Terapija bola
                </p>
                <p>
                  - PRP (Platelet-Rich Plasma) tretman
                </p>
                <p>
                  - Davanje intraartikularnih injekcija
                </p>
                <p>
                  - Davanje terapije u ordinaciji i na terenu
                </p>
                <div className="services-shape">
                  <img src="/img/shape/shape (4).webp" alt="Shape" width="252" height="223" />
                </div>
              </div>
            </div>

            <div 
              className="col-lg-auto"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              <div className="single-services">
                <h3>
                  Patronažna služba:
                </h3>
                <p>
                  - Nega majke i bebe
                </p>
                <p>
                  - Plasiranje katetera i ispiranje bešike
                </p>
                <p>
                  - Aplikacija injekcija i infuzija
                </p>
                <p>
                  - Merenje nivoa šećera u krvi
                </p>
                <p>
                  - Previjanje i sanacija svih vrsta rana
                </p>
                <p>
                  - Koncentrator kiseonika
                </p>
                <p>
                  - Inhalacije
                </p>
                <div className="services-shape">
                  <img src="/img/shape/shape (4).webp" alt="Shape" width="252" height="223" />
                </div>
              </div>
            </div>

            <div 
              className="col-lg-auto"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <div className="single-services">
                <h3>
                  Sanitetski prevoz pacijenata:
                </h3>
                <p>
                  - Prevoz bolesnika
                </p>
                <p>
                  - Prevoz nepokretnih pacijenata
                </p>
                <p>
                  - Prevoz pacijenata do banja
                </p>
                <p>
                  - Prevoz pacijenata u inostranstvo
                </p>
                <p>
                  - Medicinski transport
                </p>
                <p>
                  - Medicinska podrška javnih skupova i događaja
                </p>
                <div className="services-shape">
                  <img src="/img/shape/shape (4).webp" alt="Shape" width="252" height="223" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;