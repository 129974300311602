import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer-top-area f-bg pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                <a href="/">
                  <img src="/img/footer-logo.png" alt="Footer Hera Logo" width="560" height="162" />
                </a>

                <p>
                  Hera VaMedic, vaš pouzdani partner u zdravstvenoj nezi. Naša posvećenost pružanju vrhunskih usluga 
                  odražava se i kroz radno vreme koje je prilagođeno vašim potrebama. Radujemo se što vam možemo pružiti 
                  podršku svakog radnog dana od deset ujutru do osam uveče, kao i subotom od osam ujutru do jedan popodne. 
                  Ovaj period omogućava fleksibilnost i pristupačnost naših usluga, kako biste dobili najbolju moguću 
                  negu. Hera VaMedic - gde je vaše zdravlje naša prednost, a naša vrata su vam otvorena kako bismo vam 
                  pružili pažljivu i stručnu zdravstvenu negu.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div 
                className="single-widget open-time"
                data-aos="fade-in"
                data-aos-delay="300"
                data-aos-duration="1200"
              >
                <h3>RADNO VREME</h3>
                <ul>
                  <li>
                    <span>Ponedeljak:</span>
                    <span className="right">10:00 - 20:00</span>
                  </li>
                  <li>
                    <span>Utorak:</span>
                    <span className="right">10:00 - 20:00</span>
                  </li>
                  <li>
                    <span>Sreda:</span>
                    <span className="right">10:00 - 20:00</span>
                  </li>
                  <li>
                    <span>Četvrtak:</span>
                    <span className="right">10:00 - 20:00</span>
                  </li>
                  <li>
                    <span>Petak:</span>
                    <span className="right">10:00 - 20:00</span>
                  </li>
                  <li>
                    <span>Subota:</span>
                    <span className="right">08:00 - 13:00</span>
                  </li>
                  <li>
                    <span>Nedelja:</span>
                    <span className="right">NERADNI DAN</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div 
                className="single-widget contact"
                data-aos="fade-in"
                data-aos-delay="400"
                data-aos-duration="1200"
              >
                <h3>Kontakt</h3>
                <ul>
                  <li>
                    <span>Telefon:</span>
                    <a href="tel:+381668404040">
                      <i className="bx bx-phone-call"></i>
                      066/ 8-40-40-40
                    </a>
                    <br/>
                    <a href="tel:+381143106304">
                      <i className="bx bx-phone-call"></i>
                      014/ 310-63-04
                    </a>
                  </li>
                  <li>
                    <span>Email:</span>
                    <a href="mailto:office@heravamedic.rs">
                      <i className="bx bx-envelope"></i>
                      office@heravamedic.rs
                    </a>
                  </li>
                  <li>
                    <span>Adresa:</span>
                    <i className="bx bx-location-plus"></i>
                    Daničićeva 3, 14000 Valjevo
                  </li>
                </ul>
              </div>

              <div 
                className="single-widget"
                data-aos="fade-in"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                <div className="social-area">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/heravamedic" aria-label="Posetite našu Facebook stranicu." target="_blank" rel="noreferrer">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>          
                    <li>
                      <a href="https://www.instagram.com/heravamedic" aria-label="Posetite našu Instagram stranicu." target="_blank" rel="noreferrer">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://maps.app.goo.gl/KVGDEyggx6de3DX18" aria-label="Posetite našu Google stranicu." target="_blank" rel="noreferrer">
                        <i className="bx bxl-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-bottom-area">
        <div className="container">
          <div className="copy-right">
            <p>
              Copyright &copy; {currentYear} <a href="https://nubik.io/" target="_blank" rel="noreferrer" >NUBIK</a>. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
